<template>
  <div class="solomonica">
    <div class="main">
      <router-link to="/Gallery">
        <v-img class="banner" src="~@/assets/images/banner/banner2.png">
          <h1 v-flicker>ENTER</h1></v-img
        ></router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {
  },
};
</script>




<style lang="scss">
.solomonica {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(rgba(black, 0), rgba(black, 0.2)),
    url("~@/assets/images/background/collage1.jpg") center no-repeat;
  background-size: cover;
  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s; /* Firefox < 16 */
  -ms-animation: fadein 0.5s; /* Internet Explorer */
  -o-animation: fadein 0.5s; /* Opera < 12.1 */
  animation: fadein 0.5s;
  .banner {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s; /* Firefox < 16 */
    -ms-animation: fadein 2s; /* Internet Explorer */
    -o-animation: fadein 2s; /* Opera < 12.1 */
    animation: fadein 2s;

    h1 {
      color: yellow;
      font-size: 35px;
      display: block;
      justify-content: center;
      text-align: center;
      margin: 0;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>