<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",
};
</script>



<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Libre+Barcode+39+Text&display=swap");
* {
  margin: 0;
  padding: 0;
}
body {
  overflow-x: hidden;
  overflow-y: hidden;
}
.mobile {
  @media (min-width: 1100px) {
    display: none;
  }
}
.desktop {
  @media (max-width: 1100px) {
    display: none;
  }
}
.fade-enter {
  opacity: 0;
  transition: all 3.5s ease-in-out;
}
.enter3 {
  opacity: 1;
}
.slideFade-enter {
  opacity: 0;
  transition: all 5s ease-in;
}
.enter {
  opacity: 1;
}
.slideFade2-enter {
  opacity: 0;
  transition: all 0.5s ease-in-out;
  transform: translateX(50px);
}
.enter1 {
  opacity: 1;
  transform: translateX(0px);
}
.flicker-enter {
  opacity: 0;
  filter: hue-rotate(180deg);
  transition: all 8s ease-in-out;
  animation-delay: 0.5s;
}
.enter2 {
  opacity: 1;
  filter: hue-rotate(0deg);
}
</style>
